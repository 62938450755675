@media screen and (min-width: 769px) {
.navbar-container{
    background-color: #082052;
    padding: 1rem; 
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    z-index: 10;
    width: 100%;
    overflow: hidden;
    & .navbar-logo{
        display: flex;
        & img{
            width: 15rem;
        }
    }
    
    & .navbar-menu{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        gap: 1.5rem;
        & a{
            color: white;
            text-decoration: none;
            font-size: 1.3rem;
            font-weight: 400;
        }
    }
} 
}


@media screen and (min-width: 320px) and (max-width: 768px) {
   .navbar-container{
    background-color: #082052;
    padding: 1rem; 
    // position: fixed;
    // top: 0;
    // left: 0;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // justify-content: space-around;
    // z-index: 10;
    // width: 100%;
    // overflow: hidden;
    & .navbar-logo{
        display: flex;
        justify-content: center;
        & img{
            width: 15rem;
        }
    }
    
    & .navbar-menu{
        margin-top: 1rem;
        display: grid;
        grid-template-columns: auto auto auto auto;
        gap: 1rem;
        justify-content: center;
        & a{
            color: white;
            text-decoration: none;
            font-size: 1rem;
            font-weight: 400;
        }
    }
} 
}