@media screen and (min-width: 769px) {
    .header-container{
        display: flex;
        flex-direction: column;
        // justify-content: space-around;
        & img{
            width: 100%;
            height: 100vh;
        }
       
            & .header-contain{
                position: absolute;
                width: 20%;
                padding: 5rem 0 0 20rem;
                font-size: 4rem;
                font-weight: 500;
                color: #082052;
            }
           
    }
    
    .section1{
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        background-color: #F5F5F5;
    
        
        & .sectioncontent1{
             display: flex;
             flex-direction: column;
             justify-content: left;
             align-items: left;
             width: 50%;
             padding: 0 10rem;
             & h1{
                font-size: 2rem;
                font-weight: 500;
             }
             & hr{
                margin-left: 0rem;
                width: 10rem;
                height: 0.2rem;
                color: orange;
                background-color: orange;
                border: none;
             }
    
             & p{
                font-size: 1.2rem;
                font-weight: 400;
            }
        }
    
        & .sectionimg1{
            width: 50%;
            padding: 0rem 5rem;
            & img{
                border-radius: 1rem;
                width: 35rem;
            }
        }
    }
    
    .section2{
        display: flex;
        flex-direction: row;
        margin: 3rem 0;
        & .imgsection2{
            margin: 0 3rem;
            & img{
                border-radius: 1rem;
                width: 40rem;
                height: 50rem;
            }
        }
    
        & .contentsection2{
            margin: 3rem 5rem 3rem 0;
            margin-left: -4rem;
            display: flex;
            flex-direction: column;
            padding: 3rem;
            background-color: white;
            box-shadow: 0rem 3rem 10rem rgba(0, 0, 0, 0.1);
            border-radius: 0.5rem;
            & h1{
                font-size: 2rem;
                font-weight: 500;
            }
            & hr{
                margin-left: 0rem;
                width: 10rem;
                height: 0.3rem;
                color: orange;
                background-color: orange;
                border: none;
             }
             & p{
                padding-top: 2rem;
                font-size: 1rem;
                font-weight: 400;
             }
             & button{
                margin-top: 3rem;
                width: 15rem;
                height: 3rem;
                border-radius: 0.5rem;
                border: none;
                color: white;
                font-size: 1rem;
                font-weight: 500;
                background-color: orange;
             }
        }
    }
    
    .quotesection{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 3rem;
    
    
        & .svg{
            margin: 0 3rem;
            padding: 1rem 3rem;
        }
     & .quotecontent{
        font-size: 1.5rem;
        padding: 1rem 3rem;
     }
    
    }
    
    
    .optionsection{
        display: flex;
        flex-direction: row;
        gap: 2rem;
        padding: 2rem 4rem;
        background-color: #F5F5F5;
    
    
        & .opt1{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            box-shadow: 0rem 3rem 10rem rgba(0, 0, 0, 0.1);
            border-radius: 1rem;
            margin-top: 2rem;
            padding: 2rem 0;
            & .opt1content{
                padding: 0 2rem;
                & p{
                    text-align: center;
                    font-size: 1rem;
                }
            }
            & button{
                background-color: orange;
                border: none;
                border-radius: 0.5rem;
                width: 4rem;
                height: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    
    .section3{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
        & .contentsection3{
            display: flex;
             flex-direction: column;
             justify-content: center;
             align-items: center;
             width: 70%;
             & h1{
                font-size: 2rem;
                font-weight: 500;
             }
             & hr{
                // margin-left: 0rem;
                width: 10rem;
                height: 0.2rem;
                color: orange;
                background-color: orange;
                border: none;
             }
    
             & p{
                padding-top: 1rem;
                font-size: 1.2rem;
                font-weight: 400;
            }
    
            & h2{
                font-size: 1.2rem;
                font-weight: 500;
            }
        }
    }
    
    .footer-container{
    
     
            & .footerstarthere{
                background-color: #082052;
                padding: 2rem;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                gap: 2rem;
                
                & .footersection1{
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
            
                    & img{
                width: 20rem;
            }
            & p{
                font-size: 1rem;
                padding-top: 2rem;
                color: white;
                width: 20rem;
            }
        }
        & .footersection2{
            display: flex;
            flex-direction: column;
            
            & .footer2div{
                display: grid;
                grid-template-columns: auto auto;
                gap: 2rem;
            }
            & h1{
                color: orange;
                font-size: 1.5rem;
            }
            & hr{
                margin-left: 0rem;
             width: 5rem;
             height: 0.2rem;   
             color: orange;
             background-color: orange;
             border: none;
            }
            & p{
                font-size: 1.3rem;
                color: white;
            }
        }
    
    
        & .footersection3{
            display: flex;
            flex-direction: column;
    
            & h1{
                color: orange;
                font-size: 1.5rem;
            }
            & hr{
                margin-left: 0rem;
             width: 5rem;
             height: 0.2rem;   
             color: orange;
             background-color: orange;
             border: none;
            }
            & p{
                width: 25rem;
                font-size: 1.3rem;
                color: white;
            }
        }
        }
    }    
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .header-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & img{
            width: 100%;
            height: 80vh;
        }
       
            & .header-contain{
                position: absolute;
                // display: flex;
                // justify-content: center;
                align-items: center;
                width: 70%;
                // border: 1px solid;
                // padding: 5rem 0 0 20rem;
                font-size: 2rem;
                font-weight: 500;
                color: #082052;
            }
           
    }
    
    .section1{
        // height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        background-color: #F5F5F5;
    
        
        & .sectioncontent1{
             display: flex;
             flex-direction: column;
             justify-content: left;
             align-items: left;
            //  width: 50%;
             padding: 0 2rem;
             & h1{
                font-size: 2rem;
                font-weight: 500;
             }
             & hr{
                margin-left: 0rem;
                width: 10rem;
                height: 0.2rem;
                color: orange;
                background-color: orange;
                border: none;
             }
    
             & p{
                font-size: 1.2rem;
                font-weight: 400;
            }
        }
    
        & .sectionimg1{
            // width: 50%;
            padding: 0rem 2rem;
            & img{
                border-radius: 1rem;
                width: 15rem;
            }
        }
    }
    
    .section2{
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        margin: 3rem 0;
        & .imgsection2{
            margin: 0 2rem;
            & img{
                border-radius: 1rem;
                width: 15rem;
                // height: 50rem;
            }
        }
    
        & .contentsection2{
            margin: 1rem 2rem;
            // margin-left: -4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2rem;
            background-color: white;
            box-shadow: 0rem 3rem 10rem rgba(0, 0, 0, 0.1);
            border-radius: 0.5rem;
            & h1{
                font-size: 1.5rem;
                font-weight: 500;
            }
            & hr{
                margin-left: 0rem;
                width: 10rem;
                height: 0.3rem;
                color: orange;
                background-color: orange;
                border: none;
             }
             & p{
                font-size: 1rem;
                font-weight: 400;
             }
             & button{
                // margin-top: 3rem;
                width: 10rem;
                height: 2rem;
                border-radius: 0.5rem;
                border: none;
                color: white;
                font-size: 0.8rem;
                font-weight: 500;
                background-color: orange;
             }
        }
    }
    
    .quotesection{
        display: flex;
        flex-direction: column;
        text-align: center;
        // justify-content: space-evenly;
        padding: 2rem;
    
    
        & .svg{
            // margin: 0 3rem;
            padding: 1rem 2rem;
            // height: 1rem;
            
        }
     & .quotecontent{
        font-size: 1rem;
        // padding: 1rem 3rem;
     }
    
    }
    
    
    .optionsection{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1rem 2rem;
        background-color: #F5F5F5;
    
    
        & .opt1{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            box-shadow: 0rem 3rem 10rem rgba(0, 0, 0, 0.1);
            border-radius: 1rem;
            // margin-top: 2rem;
            padding: 2rem 0;
            & .opt1content{
                padding: 0 1rem;
                & p{
                    text-align: center;
                    font-size: 1rem;
                }
            }
            & button{
                background-color: orange;
                border: none;
                border-radius: 0.5rem;
                width: 4rem;
                height: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    
    .section3{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 0;
        & .contentsection3{
            display: flex;
             flex-direction: column;
             justify-content: center;
             align-items: center;
             width: 80%;
             & h1{
                font-size: 1.5rem;
                font-weight: 500;
             }
             & hr{
                // margin-left: 0rem;
                width: 10rem;
                height: 0.2rem;
                color: orange;
                background-color: orange;
                border: none;
             }
    
             & p{
                // padding-top: 1rem;
                font-size: 1rem;
                font-weight: 400;
            }
    
            & h2{
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }
    
    .footer-container{
    
     
            & .footerstarthere{
                background-color: #082052;
                padding: 2rem;
                display: flex;
                flex-direction: column;
                // justify-content: space-evenly;
                // gap: 2rem;
                
                & .footersection1{
                    // margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
            
                    & img{
                width: 15rem;
            }
            & p{
                font-size: 1rem;
                // padding-top: 2rem;
                color: white;
                // width: 20rem;
            }
        }
        & .footersection2{
            display: flex;
            flex-direction: column;
            
            & .footer2div{
                display: grid;
                grid-template-columns: auto auto;
                // gap: 2rem;
            }
            & h1{
                color: orange;
                font-size: 1rem;
            }
            & hr{
                margin-left: 0rem;
             width: 5rem;
             height: 0.2rem;   
             color: orange;
             background-color: orange;
             border: none;
            }
            & p{
                font-size: 1rem;
                color: white;
            }
        }
    
    
        & .footersection3{
            display: flex;
            flex-direction: column;
    
            & h1{
                color: orange;
                font-size: 1rem;
            }
            & hr{
                margin-left: 0rem;
             width: 5rem;
             height: 0.2rem;   
             color: orange;
             background-color: orange;
             border: none;
            }
            & p{
                // width: 25rem;
                font-size: 1rem;
                color: white;
            }
        }
        }
    }
    
}